<template>
  <div>
    <el-page-header @back="dynamic" content="留言管理" style="margin-bottom: 15px;">
    </el-page-header>
    <el-table
        :data="msgData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}"
        min-height="510">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="context"
          label="留言内容">
      </el-table-column>
      <el-table-column
          prop="msgName"
          label="姓名"
          width="100">
      </el-table-column>
      <el-table-column
          prop="tel"
          label="联系方式"
          width="120">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="留言时间"
          width="150">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="50">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
            <el-button
                @click="delMsg(scope.row.msgId)"
                style="padding: 2px; margin: 5px">
              <i class="el-icon-delete"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "messgae",
  data() {
    return {
      formLabelWidth: '120px',
      msgData: []
    }
  },
  mounted() {
    this.getMsgList()
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    getMsgList() {
      this.$http({
        method: 'get',
        url: '/api/msg/getMsgList'
      }).then((res) =>{
        this.msgData = res.data.data
      })
    },
    delMsg(msgId) {
      this.$confirm('是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() =>{
        this.$http({
          method: 'delete',
          url: '/api/msg/delMsgById/' + msgId
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.getMsgList()
        }).catch(() =>{
          this.$message({
            type: 'error',
            message: '删除失败'
          })
        })
      }).catch(() =>{
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>